export default function NotFound() {
    return (
        <div className="flex justify-center mt-10">
            <div className="flex flex-col gap-2 w-full px-6 md:w-3/4 lg:w-1/2">
                <h1 className="font-bold text-3xl mb-4">404 Not Found</h1>

                <p>The page you were trying to visit does not exist.</p>
            </div>
        </div>
    );
}
