import {Button, Link} from "@nextui-org/react";
import backgroundImage from "../assets/images/background.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';

export default function Home() {
    return (
        <div className="relative isolate px-6 pt-10 lg:px-8">
            <div className="mx-auto max-w-[1024px] relative flex items-center justify-center">
                <div className="hidden md:block relative w-full h-auto aspect-[15/7] rounded-3xl shadow-lg overflow-hidden">
                    <img src={backgroundImage} alt="background"
                         className="object-cover w-full h-full"/>
                    <div className="absolute inset-0 bg-black opacity-10"></div>
                </div>

                <div className="text-center md:absolute md:w-3/4">
                    <h1 className="text-4xl font-bold tracking-tight text-black md:text-white sm:text-6xl">
                        Compete in challenges with your friends
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600 md:text-gray-200">
                        Create challenges, dare your friends & earn points by competing
                    </p>
                    <div className="mt-16 flex items-center justify-center gap-x-6">
                        <Button as={Link} color="default" href="#" size="lg" variant="solid" className="text-primary font-bold bg-gray-100 md:bg-white ">
                            <FontAwesomeIcon icon={faGooglePlay} />
                            Download app
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
