export default function DeleteAccount() {
    return (
        <div className="flex justify-center mt-10">
            <div className="flex flex-col gap-2 w-full px-6 md:w-3/4 lg:w-1/2">
                <h1 className="font-bold text-3xl mb-4">Delete Account</h1>

                <div>
                    <h3 className="font-bold">How to Delete Your Account</h3>
                    <p>
                        To delete your account, go to your profile within the app.
                        Tap the three dots in the top right corner, choose "Account," and then select "Delete Account."
                        Follow the on-screen instructions to complete the process.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">Data Deletion</h3>
                    <p>
                        When you delete your account, all associated data will be permanently erased, including your profile, posts (completed challenges), and any other data you've created.
                        If you are the creator of any poules, those will also be deleted, and any points that others have earned in those pools will be lost.
                    </p>
                </div>
            </div>
        </div>
    );
}
