import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccount from "./pages/Delete-Account";
import FAQ from "./pages/FAQ";
import DeepLinkPoule from "./pages/DeepLinkPoule";
import NotFound from "./pages/NotFound";

function App() {
  return (
      <Router>
          <div className="min-h-[100dvh] pb-16 sm:pb-24 lg:pb-32">
              <Navbar />

              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/poules/:pouleId" element={<DeepLinkPoule />} />
                  <Route path="/termsofservice" element={<TermsOfService />} />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/faq">
                      <Route index element={<FAQ />} />
                      <Route path="deleteaccount" element={<DeleteAccount />} />
                  </Route>

                  <Route path="*" element={<NotFound />} />
              </Routes>
          </div>

          <Footer />
      </Router>
  );
}

export default App;
