import {
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    Link,
    NavbarMenuToggle,
    NavbarMenu, NavbarMenuItem
} from "@nextui-org/react";
import logo from "../assets/images/logo.png"
import {useState} from "react";

export default function CustomNavbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <Navbar onMenuOpenChange={setIsMenuOpen}>
            <NavbarContent>
                <NavbarMenuToggle
                    aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                    className="sm:hidden"
                />
                <NavbarBrand>
                    <Link href="/" className="flex gap-2" color="foreground">
                        <img src={logo} alt="logo" className="w-10 h-10" />
                        <p className="font-bold text-inherit">NoBalls</p>
                    </Link>
                </NavbarBrand>
            </NavbarContent>

            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem>
                        <Link color="foreground" href="/faq">
                            FAQ
                        </Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Link color="foreground" href="/termsofservice">
                            Terms of Service
                        </Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Link color="foreground" href="/privacypolicy">
                            Privacy Policy
                        </Link>
                    </NavbarItem>
                </NavbarContent>
            </NavbarContent>

            <NavbarMenu>
                <NavbarMenuItem>
                    <Link
                        color="foreground"
                        className="w-full"
                        href="/faq"
                        size="lg"
                    >
                        FAQ
                    </Link>
                </NavbarMenuItem>
                <NavbarMenuItem>
                    <Link
                        color="foreground"
                        className="w-full"
                        href="/termsofservice"
                        size="lg"
                    >
                        Terms of Service
                    </Link>
                </NavbarMenuItem>

                <NavbarMenuItem>
                    <Link
                        color="foreground"
                        className="w-full"
                        href="/privacypolicy"
                        size="lg"
                    >
                        Privacy Policy
                    </Link>
                </NavbarMenuItem>
            </NavbarMenu>
        </Navbar>
    );
}

