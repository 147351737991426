import {useParams} from "react-router-dom";
import {Link} from "@nextui-org/react";
import {useEffect} from "react";

export default function DeepLinkPoule() {
    const {pouleId} = useParams();
    const deepLinkUrl = `${process.env.REACT_APP_APP_SCHEME}://poules?joinPouleId=${pouleId}`;


    useEffect(() => {
        window.location.href = deepLinkUrl;
    }, []);

    return (
        <div className="flex justify-center mt-10">
            <div className="flex flex-col gap-2 w-full px-6 md:w-3/4 lg:w-1/2">
                <h1 className="font-bold text-3xl mb-4">Join Poule</h1>

                <Link href={deepLinkUrl}>Open the app</Link>

                <span>Don't have NoBalls yet? <Link href="#" className="text-black">Download for free</Link></span>
            </div>
        </div>
    );
}
