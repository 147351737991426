export default function PrivacyPolicy() {
    return (
        <div className="flex justify-center mt-10">
            <div className="flex flex-col gap-2 w-full px-6 md:w-3/4 lg:w-1/2">
                <h1 className="font-bold text-3xl mb-4">Privacy Policy</h1>

                <div>
                    <h3 className="font-bold">Data Collection</h3>
                    <p>
                        We collect the following personal data from our users: username, email address, password, optional profile photo, optional thumbnail photo for a pool, and optional photos and videos uploaded when completing a challenge.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">Purpose of Data Collection</h3>
                    <p>
                        The collected data is used for user account management and to enable core functionalities of the app, such as managing pools and challenges.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">Data Storage</h3>
                    <p>
                        Passwords are stored securely using bcrypt. Media such as photos and videos are stored via Google Firebase. The remaining data is temporarily stored in a database hosted on Aiven.io but will later be moved to another database host.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">Data Sharing</h3>
                    <p>
                        We do not share data with third parties, but Google Firebase may automatically track analytical data.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">User Rights</h3>
                    <p>
                        Users can view and modify their account data but cannot delete it yet. Other data, such as challenges and pools, can be viewed, modified, and deleted.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">Cookies and Tracking Technologies</h3>
                    <p>
                        We do not use cookies or tracking technologies.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">Minors</h3>
                    <p>
                        The app is not specifically designed for minors, but they can use the app. Currently, no additional measures are in place to protect the privacy of minors. It is recommended that parents supervise minors’ use of the app.
                    </p>
                </div>

                <div>
                    <h3 className="font-bold">Changes to this Privacy Policy</h3>
                    <p>
                        This privacy policy may be updated from time to time. Users are advised to regularly review the most recent version of this policy.
                    </p>
                </div>
            </div>
        </div>
    );
}
