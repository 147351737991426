export default function TermsOfService() {
    return (
        <div className="flex justify-center mt-10">
            <div className="flex flex-col gap-2 w-full px-6 md:w-3/4 lg:w-1/2">
                <h1 className="font-bold text-3xl mb-4">Terms of Service</h1>

                <div>
                    <h3 className="font-bold">Acceptance of Terms</h3>
                    <p>
                        By using the app, the user agrees to these Terms of Service. If you do not agree with these terms, do not use the app.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">User Responsibility</h3>
                    <p>
                        All participants in challenges do so at their own risk. We are not responsible for any injury, damage, or other adverse effects resulting from participating in a challenge. Users must assess for themselves whether a challenge is safe and suitable to complete.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Pools and Challenges</h3>
                    <p>
                        Users can create or join pools. The pool owner can create challenges and determine the number of points a user receives for completing them. By completing a challenge, the user receives the number of points associated with that challenge.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Content</h3>
                    <p>
                        Users can optionally upload a photo or video when completing a challenge. By uploading content, the user declares that they own the content and that it does not infringe on the rights of others. We are not responsible for content uploaded by users. Both users and pool owners have the right to remove completed challenges and associated content.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Points and Leaderboards</h3>
                    <p>
                        Everyone in a pool can see the points and leaderboard of other participants. Cheating or unfairly gaining points is not permitted. We reserve the right to suspend or delete accounts involved in fraud or abuse.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Intellectual Property</h3>
                    <p>
                        The app and all related content (excluding content uploaded by users) are owned by the developers and may not be used or reproduced without permission.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Termination of Use</h3>
                    <p>
                        We reserve the right to deny a user access to the app if they violate the terms or post inappropriate content.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Indemnification</h3>
                    <p>
                        Users agree to indemnify us from any liability, loss, damages, costs, and legal claims arising from their use of the app or their participation in challenges.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Changes to Terms</h3>
                    <p>
                        We may update these terms from time to time. It is the user's responsibility to stay informed of any changes.
                    </p>
                </div>
                <div>
                    <h3 className="font-bold">Governing Law</h3>
                    <p>
                        These terms are governed by the laws of the Netherlands. Any disputes will be submitted to the competent courts of the Netherlands.
                    </p>
                </div>
            </div>
        </div>
    );
}
